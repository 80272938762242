// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query DossierWorkflowTransitionsById($id: ID!) {
    dossierWorkflowTransitionsById(id: $id) {
      name
      workflowId
    }
  }
`;

export default QUERY;
